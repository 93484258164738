document.addEventListener("DOMContentLoaded", function () {
    const menuButton = document.querySelector(".toc-menu-button");
    const tocNavigation = document.querySelector(".toc-navigation");

    if (menuButton && tocNavigation) {
        menuButton.addEventListener("click", function () {
            tocNavigation.classList.toggle("closed");
            menuButton.classList.toggle("open", !tocNavigation.classList.contains("closed"));
        });

        tocNavigation.addEventListener("click", function (event) {
            if (event.target.tagName === "A") {
                tocNavigation.classList.add("closed");
                menuButton.classList.remove("open");
            }
        });
    }
});
